
export default {
  name: "SearchResultTableHeadersroperty",
  inheritAttrs: false,

  props: {
    /**
     * PROPERTY, PERSON, COMPANY
     */
    type: {
      type: String,
      required: true
    },

    headers: {
      type: Array,
      default: () => { return [] }
    },
  },


};
